import React from "react";
import "./YikYakCard.css";

class YikYakCard extends React.Component {  
    render() {
        const { card, onClick, style } = this.props;
    
        // Colors
        const red = "#CA392D";
        const green = "#409F91";
    
        function getCardType() {
          if (card?.id < 27) {
              return "challenge";
          } else {
              return "solution";
          }
        };
    
        function getCardColor() {
          if (card.id < 27) {
              return red;
          } else {
              return green;
          }
        }; 

      return (
        <div className="player-card" style={{style, border: "18px", borderColor: `${getCardColor()}`, borderStyle: "solid", display: 'flow'}} onClick={onClick}>
          <p className="player-card-title" style={{color: `${getCardColor()}`}}>{getCardType()}</p>
          <p className="player-card-name" style={{margin: '0', fontWeight: '600'}}>{card.title}</p>
          <p className="player-card-name" style={{fontSize: '14px', fontWeight: '400', color: '#555555'}}>{card.subtitle}</p>
          <img id="cardimg" style={{margin: 'auto', objectFit: "contain", maxWidth: '90%'}} src={require(`../assets/images/${card.id}.png`)} />
          <p className="player-card-name" style={{fontSize: '14px', fontWeight: '400', color: '#555555', textTransform: 'none'}}>{card.scenario}</p>
        </div>
      );
    }
  }
  
  export default YikYakCard;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./Pricing.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import Overlay from "../components/Overlay";
import { FaCheckCircle, FaCheckSquare, FaGgCircle, FaMinusCircle, FaRegCheckCircle, FaRegStopCircle, FaSquare } from "react-icons/fa";
import Lottie from "lottie-react";
import PricingPage from "./PricingPage";

function Pricing() {
  const [user, loading, error] = useAuthState(auth);
  const [accountType, setAccountType] = useState("basic");
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  // Colors
  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const animationData = require(`../assets/images/host-waiting.json`);

  const fetchAccountType = async () => {
    try {
      if (user == null) {
        setAccountType("basic");
        console.log("No user data");
        return;
      }
      setUserEmail(user?.email ?? "");
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const status = doc.data().status;
        if (!hasSubscription) {
          if (status == "trialing" || status == "active") {
            hasSubscription = true;
            productId = doc.data().product.id;
          }
        }
      });
      if (hasSubscription) {
        if (productId == "prod_PbBu30DBX1c1j0") {
          setAccountType("premium");
        } else if (productId == "prod_POZmffO88V5Kwe") {
          setAccountType("plus");
        }
      } else {
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const openOverlay = () => {
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchAccountType();
  }, [user, loading]);
  return (
    <div className="page-container" style={{maxWidth: '1000px'}}>
        <div style={{marginTop: '64px', display: 'flex', alignItems: 'center', marginBottom: '64px'}}>
          <div style={{marginRight: '16px'}}>
              <h4 className="dashboard-tag-left">TAILORED TO YOU</h4>
              <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '48px'}}>PRICING</h1>
              <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Find the right subscription for you and your team.</p>
          </div>
          <div style={{display: 'grid'}}>
              <img style={{objectFit: "contain", width: '150px'}} src={require(`../assets/images/49.png`)} />
          </div>
        </div>
        <div>
          <PricingPage email={userEmail} />
        </div>
        {/* <div class="padded-boxes">
            <section style={{border: '5px #268fff solid', marginBottom: '24px'}}>
                <h3 className="heading dashboard-section-title" style={{background: '#268fff', color: '#ffffff', fontSize: '14px'}}>FREE FOREVER</h3>
                <div class="padded">
                    <p className="dashboard-section-title" style={{color: '#268fff', fontWeight: '600', textAlign: 'center', fontSize: '24px'}}>BASIC</p>
                    <p style={{color: '#268fff', fontWeight: '400', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Simple Surveys</p>
                    <p style={{color: '#268fff', fontWeight: '400', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> 1 Survey Group</p>
                    <p style={{color: `#268fff`, fontWeight: '400', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk Sample Content</p>
                    <p style={{color: `${red}`, fontWeight: '400', textAlign: 'start'}}><FaMinusCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Play Virtual Pro Talk</p>
                    <p style={{color: `${red}`, fontWeight: '400', textAlign: 'start'}}><FaMinusCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk Kids - Toolkit</p>
                    <div>
                      <a className="final-submit-btn blue-btn-dash" style={{margin: '24px 0', width: 'auto'}} href={`${accountType != "basic" ? "https://billing.stripe.com/p/login/aEU5mc7T9aIcdu8dQQ" : ""}`}>{`${accountType == "basic" ? "Current Plan" : "Downgrade To Basic"}`}</a>
                    </div>
                </div>
            </section>
            <section style={{border: '5px #FBC335 solid', marginBottom: '24px'}}>
                <h3 className="heading dashboard-section-title" style={{background: '#FBC335', color: '#875E00', fontSize: '14px'}}>MOST POPULAR</h3>
                <div class="padded">
                    <p className="dashboard-section-title" style={{color: '#E39F00', fontWeight: '600', textAlign: 'center', fontSize: '24px'}}>PLUS</p>
                    <p style={{color: '#E39F00', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Unlimited Surveys</p>
                    <p style={{color: '#E39F00', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> 5 Survey Groups</p>
                    <p style={{color: '#E39F00', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk - Toolkit</p>
                    <p style={{color: '#E39F00', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Play Virtual Pro Talk</p>
                    <p style={{color: `${red}`, fontWeight: '600', textAlign: 'start'}}><FaMinusCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk Kids - Toolkit</p>
                    <a className="final-submit-btn gold-btn-dash" style={{margin: '24px 0', width: 'auto'}} href={`${accountType == "plus" ? "https://billing.stripe.com/p/login/aEU5mc7T9aIcdu8dQQ" : "https://buy.stripe.com/28o8zVcPq5Sv1Y4001"}`}>{`${accountType == "plus" ? "Current Plan" : "Subscribe for $20/month"}`}</a>
                </div>
            </section>
            <section style={{border: '5px #65b26c solid', marginBottom: '24px'}}>
                <h3 className="heading dashboard-section-title" style={{background: '#65b26c', color: '#0f4614', fontSize: '14px'}}>BEST FOR LARGE TEAMS</h3>
                <div class="padded" style={{background: 'linear-gradient(42deg, #64b26c26, #64b26c26, #64b26c26);'}}>
                    <p className="dashboard-section-title" style={{color: '#0f4614', fontWeight: '600', textAlign: 'center', fontSize: '24px'}}>PREMIUM</p>
                    <p style={{color: '#0f4614', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Unlimited Surveys</p>
                    <p style={{color: '#0f4614', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> 15 Survey Groups</p>
                    <p style={{color: '#0f4614', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk - Toolkit</p>
                    <p style={{color: '#0f4614', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Play Advanced Virtual Pro Talk</p>
                    <p style={{color: '#0f4614', fontWeight: '600', textAlign: 'start'}}><FaCheckCircle style={{verticalAlign: "text-bottom", height: "20px"}}/> Pro Talk Kids - Toolkit</p>
                    <a className="final-submit-btn green-btn-dash" style={{margin: '24px 0', width: 'auto', background: '#0f4614'}} href={`${accountType == "premium" ? "https://billing.stripe.com/p/login/aEU5mc7T9aIcdu8dQQ" : "https://buy.stripe.com/14k9DZ2aMcgT46c6os"}`}>{`${accountType == "premium" ? "Current Plan" : "Subscribe for $40/month"}`}</a>
                </div>
            </section>
        </div> */}
        {/* <feature-compare>
            <free-level>
                <h3 class="dashboard-section-title" style={{fontSize: '36px', margin: '0px'}}>BASIC</h3>
                <p class="price dashboard-section-title" style={{fontSize: '16px', marginBottom: '12px'}}>FREE FOREVER</p>
                <ul>
                    <li class="tick"><span>Feature 1</span></li>
                    <li class="tick"><span>Feature 2</span></li>
                    <li class="tick"><span>Feature 3</span></li>
                    <li class="cross"><span>Feature 4</span></li>
                    <li class="cross"><span>Feature 5</span></li>
                    <li class="cross"><span>Feature 6</span></li>
                    <li class="cross"><span>Feature 7</span></li>
                    <li class="cross"><span>Feature 8</span></li>
                </ul>
                <a class="button" href="#">Go<span> Free</span></a>
            </free-level>
            <standard-level>
                <h3 class="dashboard-section-title" style={{fontSize: '36px', margin: '0px', color: '#875E00'}}>GOLD</h3>
                <p class="price dashboard-section-title" style={{fontSize: '16px', marginBottom: '12px', color: '#875E00'}}>$10 / month</p>
                <ul>
                    <li class="tick">Feature 1</li>
                    <li class="tick">Feature 2</li>
                    <li class="tick">Feature 3</li>
                    <li class="tick">Feature 4</li>
                    <li class="tick">Feature 5</li>
                    <li class="tick">Feature 6</li>
                    <li class="cross">Feature 7</li>
                    <li class="cross">Feature 8</li>
                </ul>
                <a class="button" href="#">Go Gold</a>
            </standard-level>
            <pro-level>
                <h3 class="dashboard-section-title" style={{fontSize: '36px', margin: '0px'}}>PLATINUM</h3>
                <p class="price dashboard-section-title" style={{fontSize: '16px', marginBottom: '12px'}}>$20 / month</p>
                <ul>
                    <li class="tick"><span>Feature 1</span></li>
                    <li class="tick"><span>Feature 2</span></li>
                    <li class="tick"><span>Feature 3</span></li>
                    <li class="tick"><span>Feature 4</span></li>
                    <li class="tick"><span>Feature 5</span></li>
                    <li class="tick"><span>Feature 6</span></li>
                    <li class="tick"><span>Feature 7</span></li>
                    <li class="tick"><span>Feature 8</span></li>
                </ul>
                <a class="button" href="#">Go<span> Pro</span></a>
            </pro-level>
        </feature-compare> */}
        {isOverlayOpen && (
            <Overlay onClose={closeOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                <h2 style={{textAlign: 'center', color: gold, background: goldBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>GOLD</h2>
                <p>Unlimited access to Pro Talk content including cards and book. Gain access to all Pro Talk team survey results and insights. Create an unlimited number of survey groups and view a full history of any survey you have taken before. Also gain access to all future updates and content including Pro Talk Kids™, Pro Talk Sales™ and more!</p>
                <a className="final-submit-btn green-btn" href="https://protalkgame.com/products/pro-talk-gold" target="blank">Upgrade Now</a>
              </div>
            </Overlay>
      )}
    </div>
  );
}

export default Pricing;
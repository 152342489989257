import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

function About() {
  const navigate = useNavigate();

  const red = "#CA392D";
  const red200 = '#f2938e';
  const green = "#409F91";
  const green200 = "#49C1B2";
  const blue = "#68A6EB";
  const orange = "#DA6E2C";
  const yellow = "#E8C352";
  

  useEffect(() => {
  }, []);
  return (
    <div className="full-page-container" style={{overflow: 'visible'}}>
      <div style={{marginTop: '48px'}}>
        <h1 className="dashboard-section-title" style={{textAlign: 'center', fontSize: '64px'}}>about us</h1>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>why yik yak?</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>
            If you’ve ever been misunderstood, mislabeled, misjudged, or undervalued then you’ll understand the need for <b>yik yak</b>. 
            If you’ve ever been abandoned by a friend, or a parent, you’ll understand the difficulty <b>yik yak</b> may have prevented. 
            If you’ve ever lost a friend, you’ve felt the sting that led to <b>yik yak</b>. If you loved recess, you’ll love <b>yik yak</b>.<br></br><br></br>
            After working with a handful of students, we noticed a pattern: Many of us are not great communicators. The problem is that most of us either think 
            we’re good at it, or we know we need to improve, but we haven’t yet found the proper tools to make communication easier in the classroom or at home. 
            Ironically, we were born with an amazingly effective tool, but somewhere along the line, we were told to put that away and grow up. One of the most 
            effective tools human beings use to learn how to navigate their interactions with others, is play.<br></br><br></br>Our journey led us to the evidence that not 
            only is the desire to play innate, but that play time is a tool intended to develop survival skills, including how to communicate. The adrenaline boost 
            that comes from play is not just fun, it stimulates our brain to maximize learning. <b>yik yak</b> is the gamification of effective communication. We’d 
            love you to have this game so that your ideas and talents can be truly understood and valued.</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>our mission</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>improve communication skills at home, in school, or at work using a fun interactive play</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>our team</h2>
        <div style={{display: 'flex', gap: '24px', alignItems: 'center'}}>
            <img src={require(`../assets/images/rick1.png`)} style={{width: '200px', height: '200px', background: red, borderRadius: '100px', objectFit: 'contain'}}/>
            <div>
                <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>
                    <span style={{fontSize: '24px', fontWeight: '600'}}>rick erekson</span> has over 30 years of experience in various fields including property management, real estate, high school and university education, 
                    leadership, and entrepreneurship. His knack for seeing the big picture, creativity, and innovative problem solving skills form the foundation 
                    of his ability to help teams progress. Rick has a B.A. in Korean, a master’s in educational leadership, and a PhD in organizational psychology.</h2>
            </div>
        </div>
        <div style={{display: 'flex', gap: '24px', alignItems: 'center', marginTop: '24px'}}>
            <img src={require(`../assets/images/christine1.png`)} style={{width: '200px', height: '200px', background: green, borderRadius: '100px', objectFit: 'contain'}}/>
            <div>
                <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>christine murphy</span> has extensive experience in management, leadership, and education. Her focus is on the overall well-being of the people she consults with 
                and for, whether that’s working with groups of executives, leaders, front-line workers, coaches, or athletes. Christine has a BS in management, 
                an MBA with an emphasis in management, and a PhD in performance psychology.</h2>
            </div>
        </div>
        {/* <div style={{display: 'flex', gap: '24px', alignItems: 'center', marginTop: '24px'}}>
            <img src={require(`../assets/images/49.png`)} style={{width: '200px', height: '200px', background: blue, borderRadius: '100px', objectFit: 'contain'}}/>
            <div>
                <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>klay erekson</span> has 7+ years of experience with software development and design, building mobile apps and websites. he has the ability to put together software
                at lightning speed and ensure things are delivered at the highest quality. klay has a BS in electrical engineering and has proven his ability to
                pick up new technology stacks with ease.</h2>
            </div>
        </div> */}
        <div style={{display: 'flex', gap: '24px', alignItems: 'center', marginTop: '24px'}}>
            <img src={require(`../assets/images/39.png`)} style={{width: '200px', height: '200px', background: yellow, borderRadius: '100px', objectFit: 'contain'}}/>
            <div>
                <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>
                <span style={{fontSize: '24px', fontWeight: '600'}}>megan erekson</span> is a brilliant artist who helped provide all illustrations and artwork associated with yik yak.</h2>
            </div>
        </div>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>our partners & sponsors</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>want to become a partner or sponsor?</h2>
        <div style={{display: 'flex', marginTop: '48px', gap: '24px', marginBottom: '64px'}}>
          <a className="final-submit-btn blue-btn-dash" style={{marginBottom: '64px'}} href="mailto:klay@kedapps.com" target="blank">email us</a>
        </div>
      </div>
    </div>
  );
}
export default About;
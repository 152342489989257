import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import "./Navbar.css";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {

  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  }

  const hideNavbar = () => {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <a className='segment-item' style={{display:'flex', alignItems: 'center', fontSize: '35px', color: 'white', textDecoration: 'none'}} href='/'>yik yak</a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar} style={{paddingRight: '24px'}}>
          <FaBars />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`} style={{background: '#B9A5E9'}}>
          <ul>
            <li>
              <NavLink className={'nav-item'} to="/" onClick={() => {hideNavbar()}}>home</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/about" onClick={() => {hideNavbar()}}>about us</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://yikyak.tv" target='blank' onClick={() => {hideNavbar()}}>play</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/discover" onClick={() => {hideNavbar()}}>discover</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} style={{marginRight: '12px'}} to="/help" onClick={() => {hideNavbar()}}>need help?</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
import React from 'react';
import '../App.css';
import { FaWindowClose } from 'react-icons/fa';

const Overlay = ({ onClose, children }) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <button className="close-button" onClick={onClose}>
          <FaWindowClose style={{height: '30px', width: '30px'}}/>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Overlay;
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Overlay from "../components/Overlay";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();
  const [isChallengeSelected, setIsChallengeSelected] = useState(false);
  const [challengeTitle, setChallengeTitle] = useState("");
  const [challengeSubtitle, setChallengeSubtitle] = useState("");
  const [challengeImage, setChallengeImage] = useState(0);
  const [challengeScenario, setChallengeScenario] = useState("");

  const [isSolutionSelected, setIsSolutionSelected] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState();

  const displayedChallenges1 = [
    {"id": 1, "title": "angry glaring", subtitle: "staring • scary face • intimidating", scenario: "Someone is not giving you a turn on the slide at the playground. When you ask for a turn, they just give you an angry glare. "},
    {"id": 8, "title": "bullying", subtitle: "meanly teasing • mistreating • hurting", scenario: "Someone in the cafeteria is teasing your friend about their lunchbox again."},
    {"id": 14, "title": "excluding", subtitle: "leaving out • ignoring • eliminating", scenario: "A new girl is introduced in your class. Your teacher tells everyone to welcome her and be friendly, but some of your friends don't let her sit with your group at lunch. "},
    {"id": 19, "title": "interrupting", subtitle: "attention seeking • talking over", scenario: "You are telling your friends about your vacation, but one of them keeps interrupting with silly questions to try and make everyone laugh."}
  ]

  const displayedChallenges2 = [
    {"id": 3, "title": "being a know-it-all", subtitle: "thinking you're smarter • showing-off", scenario: "In a group at lunch, you are talking about your favorite video game. Someone else in the group tells everyone how much better they are at the game and have defeated more levels than you. "},
    {"id": 24, "title": "prying", subtitle: "nosey • interfering • eavesdropping", scenario: "You are trying to have a private conversation with a friend, but someone keeps interrupting you and trying to get involved."},
    {"id": 15, "title": "eye rolling", subtitle: "visibly annoyed • showing disrespect", scenario: "When talking about some of your important family traditions with classmates, someone rolls their eyes to let others know they think your traditions are stupid. "}
  ]

  const displayedSolutions = [
    {"id": 28, "title": "actively listening", subtitle: "open to hearing • trying to understand • being patient", scenario: "I have an open mind and heart to what you have to say. I want to understand you."},
    {"id": 32, "title": "being a confidant", subtitle: "listening • supporting • being trustworthy", scenario: "I can keep things to myself that are meant to be private."},
    {"id": 34, "title": "complimenting", subtitle: "congratulating • commending", scenario: "When I give honest compliments to others, they feel happy, and so do I."},
    {"id": 37, "title": "expressing gratitude", subtitle: "saying thank you • showing appreciation", scenario: "I tell others I'm grateful for their contributions, time, participation, and friendship."},
    {"id": 42, "title": "kind questioning", subtitle: "curious • inquiring • examining", scenario: "Even when it's hard, I ask questions to try and understand other people and difficult situations."},
    {"id": 45, "title": "repeating", subtitle: "restating • retelling • echoing", scenario: "I repeat back what you said, and ask questions to make sure I understand what I need to know or do."}

  ]

  const red = "#CA392D";
  const red200 = '#f2938e';
  const green = "#409F91";
  const green200 = "#49C1B2";
  const blue = "#68A6EB";

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  function showComingSoon() {
    alert("Single Player Game Coming Soon")
  }; 

  function getCard() {
    return {id: 5, name: "Eye Roll", detail: "I’d like you to know how stupid I think you are, but I am too arrogant to use words."}
  }

  function challengeSelected(title, subtitle, number, scenario) {
    setIsChallengeSelected(true);
    setChallengeTitle(title);
    setChallengeSubtitle(subtitle);
    setChallengeImage(number);
    setChallengeScenario(scenario);
  }

  function closeChallengeOverlay() {
    setIsChallengeSelected(false);
  }

  function solutionSelected(solution) {
    setIsSolutionSelected(true);
    setSelectedSolution(solution);
  }

  function closeSolutionOverlay() {
    setIsSolutionSelected(false);
  }

  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth < 750) {
        setOffset(0);
      } else {
        if (window.scrollY < 35) {
          setOffset(0);
        } else {
          setOffset(window.scrollY - 35);
        }
      }
    };
        // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [user, loading]);
  return (
    <div className="full-page-container" style={{overflow: 'visible'}}>
      <div style={{marginTop: '48px'}}>
        <h1 className="dashboard-section-title">combat communication<br></br><span style={{color: red200}}>challenges</span> with more confidence</h1>
        <div style={{marginTop: '48px', display: `flex`, flexFlow: 'row', flexDirection: `row`, flexWrap: `wrap`, justifyContent: `space-around`, position: 'relative', left: `-${(offset * 0.1)}px`}}>
          {displayedChallenges1.map(challenge => (
              <div className="dashboard-image" onClick={() => {challengeSelected(challenge.title, challenge.subtitle, challenge.id, challenge.scenario)}}>
              <img src={require(`../assets/images/${challenge.id}.png`)} style={{width: 'auto', maxHeight: '150px', objectFit: 'contain'}}/>
              <h3 style={{fontWeight: '600', textAlign: 'center', color: 'white'}}>{challenge.title}</h3>
            </div>
          ))}
        </div>
        <div style={{marginTop: '48px', display: `flex`, flexFlow: 'row', flexDirection: `row`, flexWrap: `wrap`, justifyContent: `space-around`, position: 'relative', right: `-${(offset * 0.1)}px`}}>
          {displayedChallenges2.map(challenge => (
              <div className="dashboard-image" onClick={() => {challengeSelected(challenge.title, challenge.subtitle, challenge.id, challenge.scenario)}}>
              <img src={require(`../assets/images/${challenge.id}.png`)} style={{width: 'auto', maxHeight: '150px', objectFit: 'contain'}}/>
              <h3 style={{fontWeight: '600', textAlign: 'center', color: 'white'}}>{challenge.title}</h3>
            </div>
          ))}
        </div>
        <div style={{display: 'flex', marginTop: '48px', gap: '24px'}}>
          <a className="final-submit-btn blue-btn-dash" href="https://buy.stripe.com/6oEbM716I80D9qw6ov" target="blank">buy the game</a>
          <a className="final-submit-btn green-btn-dash" href="/discover" target="blank">learn more</a>
        </div>
      </div>
      <div style={{marginTop: '128px'}}>
        <div>
          <h1 className="dashboard-section-title" style={{textAlign: 'center'}}>discover and <span style={{color: green200}}>learn</span> about<br></br>new communication skills</h1>
          <div style={{marginTop: '48px', display: `flex`, flexFlow: 'row', flexDirection: `row`, flexWrap: `wrap`, justifyContent: `space-around`}}>
            {displayedSolutions.map(solution => (
                <div className="dashboard-image" onClick={() => {solutionSelected(solution)}}>
                <img src={require(`../assets/images/${solution.id}.png`)} style={{width: 'auto', maxHeight: '150px', objectFit: 'contain'}}/>
                <h3 style={{fontWeight: '600', textAlign: 'center', color: 'white'}}>{solution.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{marginTop: '80px'}}>
        <h1 className="dashboard-section-title">want more <span style={{color: blue}}>yik yak</span>?</h1>
        <img style={{objectFit: "contain", height: '200px', width: '100%'}} src={require(`../assets/images/43.png`)} />
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn green-btn-dash" style={{margin: '24px auto', width: '150px', paddingLeft: '32px', paddingRight: '32px'}} href="/discover">learn more</a>
        </div>
      </div>
      {isChallengeSelected && (
        <Overlay onClose={closeChallengeOverlay}>
          <div className="scrollable-content">
            <div style={{background: 'white', borderRadius: '10px', margin: '0 auto', maxHeight: '80%', overflowY: 'auto'}}>
              <p className="card-title" style={{fontSize: '20px', fontWeight: '600', color: red}}>challenge</p>
              <p className="card-title" style={{fontSize: '32px', fontWeight: '600', marginTop: '12px', textTransform: 'lowercased'}}>{challengeTitle}</p>
              <p style={{fontSize: "16px", textAlign: "center", height: "auto", marginTop: "12px", color: "#555555"}}>{challengeSubtitle}</p>
              <img id="cardimg" style={{objectFit: "contain", width: "100%", height: '180px', marginTop: '12px'}} src={require(`../assets/images/${challengeImage}.png`)} />
              <p style={{fontSize: "16px", textAlign: "center", height: "auto", marginTop: "12px", color: "#555555"}}>{challengeScenario}</p>
              <div style={{padding: '4px 16px', background: `${red}20`, borderRadius: '8px'}}>
                <p style={{fontWeight: '600', color: red}}>why it's bad</p>
              </div>
              <div style={{padding: '4px 16px', background: `${blue}20`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                  <p style={{fontWeight: '600', color: `${blue}`}}>let's talk about it</p>
              </div>
              <div style={{padding: '4px 16px', background: `${green}20`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                  <p style={{fontWeight: '600', color: `${green}`}}>discover solutions</p>
              </div>
            </div>
          </div>
        </Overlay>
      )}
      {isSolutionSelected && (
        <Overlay onClose={closeSolutionOverlay}>
          <div className="scrollable-content">
            <div style={{background: 'white', borderRadius: '10px', margin: '0 auto', maxHeight: '80%', overflowY: 'auto'}}>
              <p className="card-title" style={{fontSize: '20px', fontWeight: '600', color: green}}>solution</p>
              <p className="card-title" style={{fontSize: '32px', fontWeight: '600', marginTop: '12px', textTransform: 'lowercased'}}>{selectedSolution.title}</p>
              <p style={{fontSize: "16px", textAlign: "center", height: "auto", marginTop: "12px", color: "#555555"}}>{selectedSolution.subtitle}</p>
              <img id="cardimg" style={{objectFit: "contain", width: "100%", height: '180px', marginTop: '12px'}} src={require(`../assets/images/${selectedSolution.id}.png`)} />
              <p style={{fontSize: "16px", textAlign: "center", height: "auto", marginTop: "12px", color: "#555555"}}>{selectedSolution.scenario}</p>
              <div style={{padding: '4px 16px', background: `${green}20`, borderRadius: '8px'}}>
                <p style={{fontWeight: '600', color: green}}>why it's good</p>
              </div>
              <div style={{padding: '4px 16px', background: `${blue}20`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                  <p style={{fontWeight: '600', color: `${blue}`}}>let's talk about it</p>
              </div>
              <div style={{padding: '4px 16px', background: `${red}20`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                  <p style={{fontWeight: '600', color: `${red}`}}>discover related challenges</p>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </div>
  );
}
export default Dashboard;
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Account from "./account/Account";
import Navbar from './components/Navbar';
import Tutorial from './tutorial/Tutorial';
import Footer from './components/Footer/Footer';
import Pricing from './pricing/Pricing'
import Discover from "./discover/Discover";
import About from "./about/About";
import Help from "./help/Help";

function App() {
  return (
    <div className="app" style={{background: '#6e55b4'}}>
      <Router>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/discover" element={<Discover />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/get-started" element={<Tutorial />} />
          <Route exact path="/products" element={<Pricing />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/help" element={<Help />} />
        </Routes>
        
      </Router>
      <Footer/>
    </div>
  );
}
export default App;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

function Help() {
  const navigate = useNavigate();

  const red = "#CA392D";
  const red200 = '#f2938e';
  const green = "#409F91";
  const green200 = "#49C1B2";
  const blue = "#68A6EB";

  

  useEffect(() => {
  }, []);
  return (
    <div className="full-page-container" style={{overflow: 'visible'}}>
      <div style={{marginTop: '48px'}}>
        <h1 className="dashboard-section-title" style={{textAlign: 'center', fontSize: '64px'}}>need help?</h1>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>what is yik yak?</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>yik yak is a fun and interactive card game that can help kids improve their communication.</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px', marginTop: '48px'}}>where can i buy yik yak?</h2>
        <h2 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '18px', marginTop: '4px', fontWeight: '400'}}>you can buy yik yak on our website! if you would like to learn more about bulk discount pricing, feel free to reach out to us directly via email or social media and we can help you order yik yak in bulk</h2>
        <div style={{display: 'flex', marginTop: '48px', gap: '24px', marginBottom: '128px'}}>
          <a className="final-submit-btn blue-btn-dash" href="mailto:klay@kedapps.com" target="blank">email us</a>
        </div>
      </div>
    </div>
  );
}
export default Help;